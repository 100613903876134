const routes = [
  {
    path: ["/", "/HelloMyFriend"],
    exact: true,
    component: "HelloMyFriend",
  },
  {
    path: ["/CoinMarketCap"],
    exact: true,
    component: "CoinMarketCap",
  },
  {
    path: ["/Home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/Login"],
    exact: true,
    component: "Login",
  },
  {
    path: ["/LandingPage"],
    exact: true,
    component: "LandingPage",
  },
  {
    path: ["/BeepBLanding"],
    exact: true,
    component: "BeepBLanding",
  },
  {
    path: ["/TaxiLanding"],
    exact: true,
    component: "TaxiLanding",
  },
  {
    path: ["/BeemaLanding"],
    exact: true,
    component: "BeemaLanding",
  },
  {
    path: ["/ETCLanding"],
    exact: true,
    component: "ETCLanding",
  },
  {
    path: ["/WavesLanding"],
    exact: true,
    component: "WavesLanding",
  },

];

export default routes;
