import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

import routes from "./config";
import GlobalStyles from "../globalStyles";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      {/* <Header /> */}
      <Switch>
        {routes.map((routeItem) => {
          const { component, path, exact } = routeItem
          return (
            <Route
              key={component}
              path={path}
              exact={exact}
              component={lazy(() => import(`../pages/${component}`))}
            />
          );
        })}
      </Switch>
      {/* <Footer /> */}
    </Suspense>
  );
};

export default Router;
